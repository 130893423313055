<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation v-if="isSent === false" step="4"/>
    <TopNavigation v-else step="0" title="Invitation Sent" icon="" />

    <!-- isSent == false only -->
    <div v-if="isSent === false" class="generalBox border shadow-sm">
        <div v-if="objLSProj.status === 'WIP'">
            <div v-if="project === null || project === undefined">
                <i class="fa-solid fa-spinner me-2 fa-lg"></i>Loading...
            </div>
            <div v-else>
                <div v-if="isPreview === false" class="row">
                    <div class="col-12">
                        <ProjTitle v-if="project" :projId="project.folderId" :isFavourite="project.isFavourite" :mode="project.mode" :permission="project.permission" 
                            :projTitle="project.folderName" :totalSignee="project.jSignee.length" :totalDoc="project.totalDocument" :status="project.status" :shred="project.shred" :shredPolicy="project.shredPolicy" :privacy="project.privacy" 
                            :isEdit="true" @refreshProj="getProject" :key="randKey" />
                    </div>

                    <div class="col-12"><hr class="mb-2 mt-3 py-0" /></div>

                    <!-- No signee notification -->
                    <div v-if="hasDocSignee === false" class="col-12 mb-3">
                        <div class="row">
                            <div class="col-5 col-sm-3 mb-2">
                                <i class="fa-solid fa-triangle-exclamation" style="font-size: 120px"></i>
                            </div>
                            <div class="col-7 col-sm-9 mb-2">
                                <div class="fw-bold mb-3" style="font-size: 20px">Oops... you may have forgotten to assign signee to the document.</div>
                                <div>Please add signee and assign the signatory.</div>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="isLoading === true" class="text-info" style="position: fixed; z-index: 1000; top: 180px; left: 46%; font-size: 14px">
                        <i class="fa-solid fa-spinner me-2 fa-lg"></i>Loading...
                    </div>

                    <!-- Doc list -->
                    <div v-if="totalDoc > 0" class="col-12">
                        <div class="table-responsive pb-5">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Document</th>
                                        <th width="80px">Signee</th>
                                        <th width="1px"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(doc) in project.jDocument" :key="doc">
                                        <td>
                                            <DocStatus :status="doc.status" :shred="doc.shred"/>

                                            <!-- <span class="me-2" :class="doc.status === 'REMOVED' ? 'text-grey' : ''">{{ doc.name.replace('.pdf', '') }}</span> -->
                                            <span v-if="doc.shred === 'STAGING'" class="isLink me-1" @click.prevent="$router.push('/pdf/view/' + project.folderId + '/' + doc.documentId + '/review')">
                                                <Popper class="popperDark" arrow hover :content="'Mark as shred'">
                                                    <span class="text-decoration-line-through">{{ doc.name.replace('.pdf', '') }}</span>
                                                </Popper>
                                            </span>
                                            <span v-else-if="doc.shred === 'PENDING'" class="isLink me-1" @click.prevent="$router.push('/pdf/view/' + project.folderId + '/' + doc.documentId + '/review')">
                                                <Popper class="popperDark" arrow hover :content="'Pending for shred approval'">
                                                    <span class="text-decoration-line-through text-grey">{{ doc.name.replace('.pdf', '') }}</span>
                                                </Popper>
                                            </span>
                                            <span v-else class="isLink me-1" @click.prevent="$router.push('/pdf/view/' + project.folderId + '/' + doc.documentId + '/review')">{{ doc.name.replace('.pdf', '') }}</span>

                                            <span v-if="doc && doc.isEncrypted === true && doc.totalOldSignature > 0" class="me-2">
                                                <Popper class="popperDark" arrow hover :content="'This document is encrypted and has ' + doc.totalOldSignature + ' existing signature' + (doc.totalOldSignature > 1 ? 's' : '') + ' found before processing, before upload'">
                                                    <i class="fa-solid fa-triangle-exclamation fa-lg text-warning"></i>
                                                </Popper>
                                            </span>
                                            <span v-else-if="doc && doc.isEncrypted === true" class="me-2">
                                                <Popper class="popperDark" arrow hover :content="'This document is encrypted'">
                                                    <i class="fa-solid fa-triangle-exclamation fa-lg text-warning"></i>
                                                </Popper>
                                            </span>
                                            <span v-else-if="doc && doc.totalOldSignature > 0" class="me-2">
                                                <Popper class="popperDark" arrow hover :content="'This document has ' + doc.totalOldSignature + ' existing signature' + (doc.totalOldSignature > 1 ? 's' : '') + ' found before processing, before upload'">
                                                    <i class="fa-solid fa-triangle-exclamation fa-lg text-warning"></i>
                                                </Popper>
                                            </span>
                                            <span v-if="doc.remark && doc.remark.length > 0" class="me-2">
                                                <Popper class="popperDark" arrow hover :content="'Public note: ' + doc.remark">
                                                    <i class="fa-solid fa-clipboard fa-lg text-brown"></i>
                                                </Popper>
                                            </span>
                                            <span v-if="doc.note && doc.note.length > 0" class="me-2" style="background-color: beige">
                                                <Popper class="popperDark" arrow hover :content="'Private note: ' + doc.note">
                                                    <i class="fa-solid fa-note-sticky fa-lg text-brown"></i>
                                                </Popper>
                                            </span>

                                            <!-- <span v-if="isSignOn === true" class="mx-2 bg-warning lblTiny">
                                                shredPolicy: {{ doc.shredPolicy }} | shred: {{ doc.shred }}
                                            </span> -->
                                        </td>
                                        <td width="40px">
                                            <span v-if="doc.jSignee.length > 0">
                                                <Popper class="popperDark" arrow hover>
                                                    <span class="badge bg-grey isTooltipsLabel"><fa icon="user" /> {{ doc.jSignee.length }}</span>
                                                    <template #content >
                                                        <div v-for="s in doc.jSignee" :key="s" class="text-start">
                                                            <span>
                                                                <span v-if="s.seq > 0" class="badge bg-grey me-1">{{s.seq}}</span>
                                                                {{ s.signeeName }} <span v-if="s.signeeId !== ''">({{s.signeeId}})</span> - {{ s.status === '' ? '?' : s.status}}
                                                            </span>
                                                        </div>
                                                    </template>
                                                </Popper>
                                            </span>
                                            <span v-else class="badge bg-grey text-white isLabel"><fa icon="user" /> 0</span>
                                        </td>
                                        <td class="text-end" width="50px">
                                            <div class="dropdown dropstart" >
                                                <a href="#" class="isLinkHover" data-bs-toggle="dropdown">
                                                    <fa icon="ellipsis-h" class="text-secondary" />
                                                </a>
                                                
                                                <ul class="dropdown-menu dropdown-menu-secondary">
                                                    <li v-if="doc.remark || doc.note"><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlAddNote" @click="getDoc(doc)">Update note</a></li>
                                                    <li v-else><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlAddNote" @click="getDoc(doc)">Add note</a></li>

                                                    <li v-if="doc.shredPolicy === 'NO_SHRED' && doc.shred === 'STAGING'"><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlUnmarkDelDoc" @click="getDoc(doc)">Unmark as shred</a></li>
                                                    <li v-if="doc.shredPolicy === 'NO_SHRED' && doc.shred === 'PENDING'"><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlUnshredDoc" @click="getDoc(doc)">Unshred</a></li>
                                                    <li v-if="doc.shredPolicy !== 'NO_SHRED'"><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlDelDoc" @click="getDoc(doc)">Shred</a></li>

                                                    <!-- <li v-if="doc.shred !== 'PENDING' && doc.shred !== 'STAGING'"><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlDelDoc" @click="getDoc(doc)">Shred</a></li>
                                                    <li v-if="isSignOn === true && doc.shred === 'STAGING'" class="bg-warning"><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlUnmarkDelDoc" @click="getDoc(doc)">Unmark as shred</a></li>
                                                    <li v-if="isSignOn === true && doc.shred === 'PENDING'" class="bg-warning"><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlUnshredDoc" @click="getDoc(doc)">Unshred</a></li> -->
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div v-if="totalDoc > 0 && hasDocSignee === true" class="col-12">
                        <div class="mb-4 text-center">
                            <!-- ORIG
                             <button v-if="getUserStatus === 'ACTIVE'" class="btn btn-secondary text-white" @click="sendInvitation">
                                <fa icon="envelope" size="lg" class="me-2" />Send Sign Request
                            </button>
                            <button v-else class="btn btn-secondary text-white" data-bs-toggle="modal" data-bs-target="#mdlActivate" >
                                <fa icon="envelope" size="lg" class="me-2" />Send Sign Request
                            </button> -->

                            <div v-if="isSignOn === true" class="btn-group bg-warning">
                                <button v-if="getUserStatus === 'ACTIVE' && showSealBtn === true" class="btn btn-outline-secondary">
                                    <i class="fa-solid fa-stamp me-2"></i>Seal Folder
                                </button>
                                <button v-if="getUserStatus === 'ACTIVE'" class="btn btn-secondary text-white" @click="sendInvitation">
                                    <i class="fa-solid fa-lg me-2" :class="btnSendLabel === 'Send Shred Request' ? 'fa-trash' : 'fa-envelope'"></i>{{ btnSendLabel }}
                                    <!-- {{ project.totalDPendingCirculation === 0 ? 'Resume Circulation' : 'Send Sign Request' }} -->
                                </button>
                                <button v-else class="btn btn-secondary text-white" data-bs-toggle="modal" data-bs-target="#mdlActivate" >
                                    <i class="fa-solid fa-lg me-2" :class="btnSendLabel === 'Send Shred Request' ? 'fa-trash' : 'fa-envelope'"></i>{{ btnSendLabel }}
                                    <!-- {{ project.totalDPendingCirculation === 0 ? 'Resume Circulation' : 'Send Sign Request' }} -->
                                </button>
                                <button class="btn btn-secondary dropdown-toggle dropdown-toggle-split" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end p-0 bg-warning">
                                    <li class="my-0">
                                        <div class="py-2 px-3">
                                            <div class="my-2">Sign request expiry</div>
                                            <VueDatePicker v-model="dateExpiry" model-type="yyyy-MM-dd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                                            
                                            <div class="lblTiny my-2">All the documents in the folder will be blocked from signing<br /> after the expiry date, or until editor reviewed the expiry date.</div>
                                            <div class="my-2 text-end">
                                                <button class="btn btn-secondary" @click="updDateExpiry()">
                                                    <fa icon="floppy-disk" size="lg" class="me-2" />Save
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div v-if="isSignOn === false">
                                <button v-if="getUserStatus === 'ACTIVE'" class="btn btn-secondary text-white" @click="sendInvitation">
                                    <i class="fa-solid fa-lg me-2" :class="btnSendLabel === 'Send Shred Request' ? 'fa-trash' : 'fa-envelope'"></i>{{ btnSendLabel }}
                                </button>
                                <button v-else class="btn btn-secondary text-white" data-bs-toggle="modal" data-bs-target="#mdlActivate" >
                                    <i class="fa-solid fa-lg me-2" :class="btnSendLabel === 'Send Shred Request' ? 'fa-trash' : 'fa-envelope'"></i>{{ btnSendLabel }}
                                </button>
                            </div>

                            <!-- <div v-if="isSignOn === true" class="dropdown">
                                <button @click="sendInvitation" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <fa icon="envelope" size="lg" class="me-2" />
                                    <span class="pe-2">Send Sign Request</span>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end p-0">
                                    <li class="my-0">
                                        <div class="py-2 px-3">
                                            <div class="my-2">Sign request expiry</div>
                                            <VueDatePicker v-model="dateExpiry" model-type="yyyy-MM-dd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                                            
                                            <div class="lblTiny my-2">All the documents in the folder will be blocked from signing<br /> after the expiry date, or until editor reviewed the expiry date.</div>
                                            <div class="my-2 text-end">
                                                <button class="btn btn-secondary" @click="updDateExpiry()">
                                                    <fa icon="floppy-disk" size="lg" class="me-2" />Save
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div> -->
                            
                        </div>

                        <div class="col-12"><br /></div>
                        <div class="col-12 mb-2">Message to Signee(s)</div>

                        <div class="col-12 mb-2">
                            <textarea :id="isFocusMsg === true ? '' : 'userMsg'" rows="4" v-model="msg" @input="filterMsg" @focus="isFocusMsg = true" @blur="isFocusMsg = false" class="text-secondary fst-italic" placeholder="Kindly review and sign the document(s). &#10; &#10;Thanks!"></textarea>
                        </div>

                        <div class="mb-3">
                            <span class="text-primary isLink" @click="viewOnline">
                                <i class="fa-solid fa-user-magnifying-glass fa-lg me-2"></i>Preview Signing Page as Signee
                            </span>

                            <span class="text-primary isLink float-end" @click="isPreview = true">
                                <i class="fa-solid fa-envelope-open-text fa-lg me-2"></i>Preview Email Invitation
                            </span>
                        </div>
                    </div>
                    
                </div>

                <div v-if="isPreview === true" class="row">
                    <div class="col-12 mb-2">
                        <label class="fw-bold">Email Preview</label>
                        <button class="btn btn-secondary text-white float-end" @click="isPreview = false">
                            <i class="fa-solid fa-xmark fa-lg me-2"></i>Close
                        </button>
                    </div>
                    <div v-if="getUserStatus === 'ACTIVE'" class="col-12 previewOuterBox" >
                        <div class="border p-3 pb-1 form-control previewBox">
                            <div class="mb-2" v-html="msgHeaderHtml"></div>
                            <div v-if="msg === null || msg === ''" class="text-secondary fst-italic pb-3">Kindly review and sign the document(s). <br /><br />Thanks!</div>
                            <div v-else class="text-secondary fst-italic" v-html="msg.replaceAll('\n', '<br />')"></div>
                            <div class="mb-0" v-html="msgFooterHtml"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="objLSProj.status !== 'WIP'">
            <div class="row">
                <div class="col-5 col-sm-3 mb-2">
                    <i class="fa-solid fa-triangle-exclamation" style="font-size: 120px"></i>
                </div>
                <div class="col-7 col-sm-9 mb-2">
                    <div class="fw-bold mb-3" style="font-size: 20px">Hmmm... We're sorry, but you don't have permission to view this page.</div>
                    <div>Please email to <a href="mailto:wecare@signon.my" ><u>wecare@signon.my</u></a> for more information.</div>
                    <div>Or you may search <span class="text-primary isLink" @click="$router.push('/project')">folder</span> from the rack again.</div>
                </div>
                <div class="col-12"><hr class="my-3"/></div>
                <div class="col-12 text-center">
                    <button class="btn btn-secondary" @click="$router.push({ path: '/project' })">
                        <i class="fa-solid fa-folder me-2 fa-lg"></i>Folder
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- isSent = false only end -->

    <!-- Sent successfully -->
    <div v-if="isSent === true" class="generalBox shadow-sm border" style="padding-top: 0px; padding-left: 0px; padding-right: 0px;">

        <div class="alert alert-success mb-0 headerSuccess">
            <table border="0">
                <tr>
                    <td rowSpan="2" width="70px"><fa icon="paper-plane" style="font-size: 50px" /></td>
                </tr>
                <tr >
                    <td>
                        <div class="fw-bold">Success</div>
                        <div>Your sign request is sent successfully!</div>
                    </td>
                </tr>
            </table>
        </div>

         <div class="row bodySuccess">
            <div class="col-lg-6">
                <div class="row">
                    <div v-if="project" class="col-12 mb-3">
                        <span class="fw-bold me-1">{{ project.folderName }}</span>
                        <Popper class="popperDark" arrow hover content="Total document">
                            <span class="badge bg-grey isTooltipsLabel me-1">
                                <fa icon="file" /> {{ project.totalDocument }}
                            </span>
                        </Popper>
                        <!-- Tmp hide: pendign from API
                         <Popper class="popperDark" arrow hover content="Total signee">
                            <span class="badge bg-grey isTooltipsLabel me-1"><fa icon="user" /> 0</span>
                        </Popper> -->
                    </div>

                    <div v-if="totalDocPendingForCirculation === 0" class="col-lg-12 mb-4">
                        <table>
                            <tr>
                                <td>Credit Spent</td>
                                <td class="fw-bold">0</td>
                            </tr>
                            <tr>
                                <td class="pe-3">Total Signee</td>
                                <td class="fw-bold">{{project.jSignee.length}}</td>
                            </tr>
                        </table>
                    </div>
                    <div v-else class="col-lg-12 mb-4">
                        <table>
                            <!-- Tmp hide: pending from API
                             <tr>
                                <td>Total Usage</td>
                                <td>0 MB</td>
                            </tr> -->
                            <tr>
                                <td>Credit Spent</td>
                                <!-- Tmp hide: pending currency from API
                                 <td class="fw-bold">USD {{creditSpent}}</td> -->
                                <td class="fw-bold">{{creditSpent.toLocaleString()}}</td>
                            </tr>
                            <tr>
                                <td class="pe-3">Credit Balance</td>
                                <!-- Tmp hide: pending currency from API
                                 <td>USD {{creditBal}}</td> -->
                                <td>{{creditBal.toLocaleString()}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <!-- FAQ -->
            <div class="col-lg-6 mb-3">
                <div class="row">
                    <div v-if="creditBal < 3" class="col-12 mb-3">
                        <div class="mb-1">Your credit balance is quite low?</div>
                        <div class="isLinkPrimary mb-2" @click="showUpgrade = !showUpgrade">
                            <fa icon="circle-question" size="lg" class="me-2" />Learn how to increase your credit balance
                        </div>
                        <div v-if="showUpgrade" class="row">
                            <div class="col-12 mb-2">
                                <table>
                                    <tr>
                                        <td class="pb-2" :class="payInYear === true ? '' : 'text-primary'">Pay Monthly</td>
                                        <td class="ps-3 pe-2">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" v-model="payInYear" value="true">
                                            </div>
                                        </td>
                                        <td class="pb-2" :class="payInYear === true ? 'text-primary' : ''">Pay Yearly</td>
                                    </tr>
                                </table>
                            </div>

                            <div class="col-lg-6 mb-3">
                                <div class="contentBox border shadow-sm">
                                    <div class="fw-bold text-center mb-1">
                                        <span>Plan 30</span>
                                    </div>
                                    <div class="text-center">
                                        <span class="fw-bold text-uppercase">USD</span>
                                        <span v-if="payInYear === false" class="fs-1 ps-1">5</span>
                                        <span v-if="payInYear === true" class="fs-1 ps-1">55</span>
                                        <span v-if="payInYear === false"> / month</span>
                                        <span v-if="payInYear === true"> / year</span>
                                    </div>
                                    <div class="text-center"><hr /></div>
                                    <div class="mb-2">
                                        <i class="fa-solid fa-check me-3 fa-lg"></i><span class="fw-bold">30</span> credits / user
                                    </div>
                                    <div class="mb-2">
                                        <i class="fa-solid fa-check me-3 fa-lg"></i><span class="fw-bold">1GB</span> storage / user
                                    </div>
                                    <div class="text-center pt-4">
                                        <button class="btn btn-secondary">
                                            <i class="fa-solid fa-hand-pointer me-2"></i>Upgrade
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 mb-3">
                                <div class="contentBox border shadow-sm">
                                    <div class="fw-bold text-center mb-1">
                                        <span>Plan 300</span>
                                    </div>
                                    <div class="text-center">
                                        <span class="fw-bold text-uppercase">USD</span>
                                        <span v-if="payInYear === false" class="fs-1 ps-1">42</span>
                                        <span v-if="payInYear === true" class="fs-1 ps-1">464</span>
                                        <span v-if="payInYear === false"> / month</span>
                                        <span v-if="payInYear === true"> / year</span>
                                    </div>
                                    <div class="text-center"><hr /></div>
                                    <div class="mb-2">
                                        <i class="fa-solid fa-check me-3 fa-lg"></i><span class="fw-bold">300</span> credits / user
                                    </div>
                                    <div class="mb-2">
                                        <i class="fa-solid fa-check me-3 fa-lg"></i><span class="fw-bold">5GB</span> storage / user
                                    </div>
                                    <div class="text-center pt-4">
                                        <button class="btn btn-secondary">
                                            <i class="fa-solid fa-hand-pointer me-2"></i>Upgrade
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 mb-3">
                                <div class="contentBox border shadow-sm">
                                    <div class="fw-bold text-center mb-1">
                                        <span>Plan 1000</span>
                                    </div>
                                    <div class="text-center">
                                        <span class="fw-bold text-uppercase">USD</span>
                                        <span v-if="payInYear === false" class="fs-1 ps-1">120</span>
                                        <span v-if="payInYear === true" class="fs-1 ps-1">1,325</span>
                                        <span v-if="payInYear === false"> / month</span>
                                        <span v-if="payInYear === true"> / year</span>
                                    </div>
                                    <div class="text-center"><hr /></div>
                                    <div class="mb-2">
                                        <i class="fa-solid fa-check me-3 fa-lg"></i><span class="fw-bold">1,000</span> credits / user
                                    </div>
                                    <div class="mb-2">
                                        <i class="fa-solid fa-check me-3 fa-lg"></i><span class="fw-bold">30GB</span> storage / user
                                    </div>
                                    <div class="text-center pt-4">
                                        <button class="btn btn-secondary">
                                            <i class="fa-solid fa-hand-pointer me-2"></i>Upgrade
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="getUserIsVerified === false || getUserIsVerified === 'false'" class="col-12">
                        <div class="mb-1">Oops, you have not verified your profile?<br />Complete your KYC in order to request or to sign highly confidential documents by update your profile.</div>
                        <div>
                            <span class="text-primary isLink" @click="$router.push({ path: '/auth/preference', query: { ct: 'kyc' } })">
                                <i class="fa-solid fa-address-card me-2"></i>Proceed KYC
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- FAQ end -->

            <div class="col-lg-12">
                <hr class="my-3"/>
            </div>

            <div class="col-lg-12">
                <div class="btn-group">
                    <button class="btn btn-outline-secondary mb-2" @click="$router.push({ path: '/project' })">
                        <i class="fa-solid fa-folder me-2"></i>Back to Folder
                    </button>
                    <button class="btn btn-outline-secondary mb-2 float-end" @click="$router.push({ path: '/uploadDoc' })">
                        <i class="fa-solid fa-folder-plus me-2"></i>Add Folder
                    </button>
                </div>

                <button v-if="singleDocNSignee === true" class="btn btn-secondary float-end" @click="$router.push({ path: lnkSignNow })">
                    <i class="fa-solid fa-signature me-2"></i>Sign Now
                </button>
                <button v-else class="btn btn-secondary float-end" @click="$router.push({ path: '/p/' + objLSProj.id })">
                    <i class="fa-solid fa-file-lines me-2"></i>View as Signee
                </button>
            </div>
         </div>
        
    </div> 

    <!-- Modal: Add note --> 
    <div class="modal fade" id="mdlAddNote" tabindex="-1" aria-labelledby="AddNote" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 v-if="docDetail" class="modal-title" id="exampleModalLabel"> Add note :: {{ docName }}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <label class="form-label text-secondary">
                                <i class="fa-solid fa-clipboard fa-lg text-brown me-2"></i>Signee's note
                            </label>
                            <textarea v-model="publicNote" rows="5" class="form-control" maxlength="100"></textarea>
                        </div>
                        <div class="col-12">
                            <label class="form-label text-secondary">
                                <i class="fa-solid fa-note-sticky fa-lg text-brown me-2"></i>Private editor's note</label>
                            <textarea v-model="selfNote" rows="2" class="form-control" maxlength="80"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Cancel
                    </button>
                    <button class="btn btn-secondary" data-bs-dismiss="modal" @click="addNote">
                        <i class="fa-solid fa-floppy-disk me-2"></i>Save
                    </button>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div><!-- Modal End-->

    <!-- Modal: Delete document --> 
    <div class="modal fade" id="mdlDelDoc" tabindex="-1" aria-labelledby="mdlDelDoc" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Shred document</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="docDetail">

                        <div v-if="docDetail.totalOldSignature > 0" class="mb-2">This document has {{ docDetail.totalOldSignature }} existing signature{{ docDetail.totalOldSignature > 1 ? 's' : '' }} found before processing, before upload and it is unrecoverable after shredded.</div>

                        <div v-if="docDetail.shredPolicy === 'IMMEDIATE'">
                            <div class="mb-2">This document will be shredded immediately and unrecoverable.</div>
                            <div>Confirm to shred <strong>{{docName}}</strong> document?</div>
                        </div>
                        <div v-if="docDetail.shredPolicy === 'WITHOUT_APPROVAL'">
                            <div class="mb-2">This document will mark as shred until you circulate it, after which it will be shredded immediately.</div>
                            <div>Confirm to mark <strong>{{docName}}</strong> document as shred?</div>
                        </div>
                        <div v-if="docDetail.shredPolicy === 'WITH_APPROVAL'">
                            <table>
                                <tr>
                                    <td colspan="2">
                                        <div class="mb-2">This document will mark as shred until you circulate it.</div>
                                        <div class="mb-2 fw-bold">During circulating:</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pb-3"><i class="fa-solid fa-trash fa-3x me-3"></i></td>
                                    <td class="pb-3">It will be shredded after receving approval from all signees who have signed previously.</td>
                                </tr>
                                <tr>
                                    <td class="pb-3"><i class="fa-solid fa-calendar-days fa-3x me-3"></i></td>
                                    <td class="pb-3">It will be kept for {{ objLSPlan.dayToDeleteShreddedSealedDocument }} days, and during this period the document is still accessible by signees.</td>
                                </tr>
                            </table>
                            <div>Confirm to mark <strong>{{docName}}</strong> as shred?</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="justify-content: space-between;">
                    <button v-if="docDetail.shredPolicy === 'IMMEDIATE'" class="btn btn-secondary" data-bs-dismiss="modal" @click="delDoc()">
                        <i class="fa-solid fa-trash me-2"></i>Shred
                    </button>
                    <button v-if="docDetail.shredPolicy === 'WITH_APPROVAL' || docDetail.shredPolicy === 'WITHOUT_APPROVAL'" class="btn btn-secondary" data-bs-dismiss="modal" @click="delDoc()">
                        <i class="fa-solid fa-trash me-2"></i>Mark as Shred
                    </button>

                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Cancel
                    </button>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- Modal End-->

    <!-- Modal: Unmark as shred doc -->
    <div
      class="modal fade"
      id="mdlUnmarkDelDoc"
      tabindex="-1"
      aria-labelledby="unmarkDelDoc"
      aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Unmark shred document</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>Document <strong>{{ docName }}</strong> was marked for shredding previously, confirm to unmark?</div>
                </div>
                <div class="modal-footer" style="justify-content: space-between;">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Cancel
                    </button>
                    <button type="button" class="btn btn-secondary" @click="unmarkDoc" data-bs-dismiss="modal" >
                        <i class="fa-solid fa-trash-xmark me-2"></i>Unmark
                    </button>
                </div>
            </div><!-- modal-content -->
        </div>
    </div>  

    <!-- Modal: Unshred /cancel shred doc -->
    
    <div
      class="modal fade"
      id="mdlUnshredDoc"
      tabindex="-1"
      aria-labelledby="unshredDoc"
      aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Unshred document</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>Are you sure you want to cancel the shredding of the <strong>{{ docName }}</strong> document?</div>
                </div>
                <div class="modal-footer" style="justify-content: space-between;">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Cancel
                    </button>
                    <button type="button" class="btn btn-secondary" @click="unshredDoc" data-bs-dismiss="modal" >
                        <i class="fa-solid fa-trash-slash me-2"></i>Unshred
                    </button>
                </div>
            </div><!-- modal-content -->
        </div>
    </div>  

    <!-- Modal: Email Verification Required --> 
    <div class="modal fade" id="mdlActivate" tabindex="-1" aria-labelledby="Invite" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Email validation required</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="pb-2">One last step to sign a document.</div>
                    <div class="pb-2">Signature can be forged physically or digitally (aka Picture Signature). It is important to sign a document with a validated email to ensure you are the actual signee. SignOn traces the signing process with full Audit Report.</div>
                    <div class="mb-2">Please validate your email {{getUserId}} from your INBOX. If you do not receive the validation email, please request again here.</div>
                    
                    <div class="mb-2">
                        <table width="100%">
                            <tr>
                                <td width="45%"><hr /></td>
                                <td class="text-center">OR</td>
                                <td width="45%"><hr /></td>
                            </tr>
                        </table>
                    </div>
                    <div v-if="captchaImgResend">
                        <table width="100%" border="0">
                            <tr>
                                <td width="200px" class="mb-2">
                                    <img :src="captchaImgResend" class="border bg-info" style="position:absolute; width: 200px; height: 100px" />
                                    <button class="btn btn-secondary btn-sm mb-3" @click="getCaptchaResend" style="z-index: 10000; opacity: 0.5; float: right; margin-top: 68px">
                                        <Popper class="popperDark" arrow hover content="Refresh Captcha">
                                            <fa icon="refresh" />
                                        </Popper>
                                    </button>
                                </td>
                                <td>&nbsp;</td>
                                <td class="align-top"><input v-model="captchaResend" class="form-control" placeholder="Captcha"></td>
                            </tr>
                        </table>
                        
                    </div>
                    
                    <div class="d-grid">
                        <button class="btn btn-secondary btn-block" @click="resendActivation" data-bs-dismiss="modal">
                            <fa icon="envelope" size="lg" class="me-2" />Resend Activation Email
                        </button>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>Cancel
                    </button>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- Modal End-->
    
</template>

<script>
import { ref, inject, computed, onMounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useStore } from '@/stores/store'
import Popper from 'vue3-popper'
import Alert from '@/components/Alert.vue'
import ProjStatus from '@/components/ProjStatus.vue'
import DocStatus from '@/components/DocStatus.vue'
import ProjPrivacy from '@/components/ProjPrivacy.vue'
import funcs from '@/functions/function'
import { useRoute, useRouter } from 'vue-router'
import ProjTitle from '@/components/ProjTitle.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'ReviewInvite',
    components: { TopNavigation, Popper, Alert, ProjStatus, DocStatus, ProjPrivacy, ProjTitle, VueDatePicker },
    setup() {

        const axios = inject('axios')
        const alert = ref([])
        const store = useStore()
        const { getUserStatus, getUserId, getUserIsVerified, getPackage } = useStore()
        const func = funcs
        const route = useRoute()
        const router = useRouter()

        const isSignOn = ref(false) // detect if from signon Entity

        const isSent = ref(false)
        const isLoading = ref(false) // indicate after click send sign request
        const sessionId = ref(null)
        const project = ref(null)
        const totalDoc = ref(0)
        const subject = ref(null)
        const email = ref(null)
        const msg = ref(null)
        const msgHeader = ref(null)
        const msgFooter = ref(null)
        const msgHeaderHtml = ref(null)
        const msgFooterHtml = ref(null)
        const publicNote = ref(null)
        const selfNote = ref(null)
        const projectStatus = ref(null)
        const isEditMode = ref(false) // Edit project name. (true/false) editable mode/readonly mode
        const docDetail = ref({})
        const docName = ref(null)
        const hasDocSignee = ref(false) // if assigned any signee to document
        const singleDocNSignee = ref(false)  // if only 1 doc and owner is the only one signee, show SignNow button
        const lnkSignNow = ref('')
        const isFocusMsg = ref(false)
        const isPreview = ref(false)
        
        const captchaImgResend = ref(null)
        const refIdResend = ref(null)
        const captchaResend = ref(null)
        
        const randKey = ref(1)
        const objLSProj = ref({})
        const objLSPlan = ref({})

        const creditSpent = ref(0)
        const creditBal = ref(0)
        const showKyc =ref(false)
        const showUpgrade =ref(false)
        const payInYear = ref(false)

        const dateExpiry = ref('')
        const totalDocPendingForCirculation = ref(0)
        const showSealBtn = ref(false)  // Seal Folder button
        const btnSendLabel = ref('Send Sign Request') //Send Sign Request/Resume Circulation/Send Shred Request/Send

        sessionId.value = store.getSessionId.value
        email.value = store.getUserId.value
        msg.value = ''


        watch([docDetail], () => {
            publicNote.value = docDetail.value.remark
            selfNote.value = docDetail.value.note

        })

        const unmarkDoc = () => {
            // console.info('unmarkDoc', docName.value, 'pid:', objLSProj.value.id)
            // console.info('unmarkDoc', 'id:', docDetail.value.documentId, 'name:', docDetail.value.name)

            axios.put( '/signon/' + objLSProj.value.id + '/' + docDetail.value.documentId + '/cancelShred', {})
            .then((res) => {
                // console.info('- - unmarkDelDoc', JSON.stringify(res))
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else  if (res.data.status === 1) {

                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "The document '" + docName.value + "' has been unmarked for shredding."
                    })
                    setTimeout(() => getProject(objLSProj.value.id), 1000)

                } else {
                    func.addLog('review', 'unmarkDoc', res)

                    if (res && res.data !== null && res.data !== undefined) {

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                    }
                }

            })
            .catch((error) => {
                func.addLog('review', 'unmarkDoc - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })
        }

        const unshredDoc = () => {
            // console.info('unshredDoc', docName.value, 'pid:', objLSProj.value.id)
            // console.info('unshredDoc', 'id:', docDetail.value.documentId, 'name:', docDetail.value.name)

            axios.put( '/signon/' + objLSProj.value.id + '/' + docDetail.value.documentId + '/cancelShred', {})
            .then((res) => {
                console.info('- - unshredDoc', JSON.stringify(res))
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else  if (res.data.status === 1) {

                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "The shredding of the document '" + docName.value + "' has been cancelled."
                    })
                    setTimeout(() => getProject(objLSProj.value.id), 1000)

                } else {
                    func.addLog('review', 'unshredDoc', res)

                    if (res && res.data !== null && res.data !== undefined) {

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                    }
                }

            })
            .catch((error) => {
                func.addLog('review', 'unshredDoc - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })
        }

        const getDoc = (doc) => {
            docDetail.value = doc
            docName.value = doc.name.replace('.pdf', '')
        }

        const updDateExpiry = () => {
            if (dateExpiry.value === null) {
                dateExpiry.value = ''
            }
            // console.info('updDateExpiry', dateExpiry.value)
            const p = {
                exDay: dateExpiry.value
            }

            axios.put( '/signon/' + objLSProj.value.id, p)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                           
                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Sign request expiry date is updated successfully."
                            })


                        } else {
                            func.addLog('review', 'updDateExpiry', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('review', 'updDateExpiry - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    })
        }

        const viewOnline = () => {
            let route = router.resolve({ path: '/p/' + project.value.folderId })
            window.open(route.href)

        }

        const addNote = () => {
            const p = {
                remark: publicNote.value,
                note: selfNote.value
            }

            axios.put( '/signon/' + objLSProj.value.id + '/' + docDetail.value.documentId, p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        docDetail.value = {}
                        getProject(objLSProj.value.id)

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Note is updated."
                        })

                    } else {
                        func.addLog('review', 'addNote', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('review', 'addNote - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const getProject = (id) => {
            const p = {
                orderBy: [
                    {
                        field: 'jAuditLog',
                        order: 'desc'
                    }
                ]
            }
            
            return axios.post( '/signon/' + id, p)
                .then((res) => {
                    
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        randKey.value = randKey.value + 1
                        project.value = res.data.data
                        totalDoc.value = res.data.data.totalDocument
                        dateExpiry.value = res.data.data.dateExpiry === 0 ? func.convDateTimeFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 14), 'date') : res.data.data.dateExpiry

                        // console.info('getproj', JSON.stringify(res.data.data))
                        getBtnSendLabel(res.data.data.shred, res.data.data.jDocument)

                        // Check if assigne doc signee
                        if (res.data.data.jDocument && res.data.data.jDocument.length > 0) {
                            // console.info('- - jDoc', res.data.data.jDocument.length)
                            for (var i = 0; i < res.data.data.jDocument.length; i++) {

                                // Check if signle doc and creator is the only one signee
                                if (res.data.data.jDocument.length === 1 && res.data.data.jDocument[i].jSignee.length === 1) {
                                    if (res.data.data.jDocument[i].jSignee[0].signeeId === store.getUserId.value) {
                                        singleDocNSignee.value = true
                                        lnkSignNow.value = '/p/' + res.data.data.folderId + '/' + res.data.data.jDocument[i].documentId

                                    }
                                }

                                if (res.data.data.jDocument[i].jSignee && res.data.data.jDocument[i].jSignee.length > 0) {
                                    hasDocSignee.value = true
                                }
                            }
                        }

                        // Update latest project local storage
                        let objLSProj = { id: res.data.data.folderId, name: res.data.data.folderName, status: res.data.data.status}
                        localStorage.setItem('project', funcs.encodeJsonToStrSimple(objLSProj))

                    } else {
                        func.addLog('review', 'getProject', res)
                        if (res && res.data !== null && res.data !== undefined) {
                            // console.info('yn', res.data)
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('review', 'getProject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                }) 
        }

        const getBtnSendLabel = async (folderShred, jDoc) => {
            let hasDraft = false
            let hasShredReq = false
            let hasInvitedSigned = false
           
            if (jDoc && jDoc.length > 0) {
                // console.info('- - jDoc', jDoc.length)
                for (var i = 0; i < jDoc.length; i++) {
              
                    if (jDoc[i].status === 'DRAFT') {
                        hasDraft = true
                    }

                    if (jDoc[i].status === 'INVITED' || jDoc[i].status === 'SIGNED' ) {
                        hasInvitedSigned = true
                    }

                    if (folderShred === 'STAGING' || jDoc[i].shred === 'STAGING') {
                        hasShredReq = true
                    }
                }
            }

            if (hasDraft === true) {
                if (hasShredReq === true) {
                    btnSendLabel.value = 'Send Sign & Shred Requests'
                } else {
                    btnSendLabel.value = 'Send Sign Request'
                }
            } else {
                if (hasShredReq === true) {
                    btnSendLabel.value = 'Send Shred Request'
                } else {
                    btnSendLabel.value = 'Resume Circulation'
                    if (hasInvitedSigned === true) {
                        showSealBtn.value = false
                    } else {
                        showSealBtn.value = true
                    }
                }
            }
        }

        const getEmailTemplate = () => {
            return axios.get( '/reference/emailTemplate/sInvite/' + objLSProj.value.id)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        subject.value = res.data.data.subject
                        msgHeader.value = res.data.data.header
                        msgFooter.value = res.data.data.footer
                        msgHeaderHtml.value = res.data.data.headerHtml
                        msgFooterHtml.value = res.data.data.footerHtml

                    } else {
                        func.addLog('review', 'getEmailTemplate', res)

                        if (res && res.data !== null && res.data !== undefined){

                            if(res.data.message === 'not_activated') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Kindly activate your account to gain full access privileges.'
                                })

                            } else {

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                            }

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('review', 'getEmailTemplate - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const filterMsg = () => {
            const regexForStripHTML = /(<([^>]+)>)/ig
            // Filter inner text in tags | const regexForStripHTML = /<([^</> ]+)[^<>]*?>[^<>]*?<\/\1> */gi
            const stripMsg = msg.value.replaceAll(regexForStripHTML, '')
            msg.value = stripMsg

        }

        const sendInvitation = () => {
            isLoading.value = true
            const p = {
                body: msg.value === '' ? 'Kindly review and sign the document(s).<br><br>Thanks!' : msg.value
            }

            console.info('sendInvitation', JSON.stringify(p))
            axios.put( '/signon/' + objLSProj.value.id + '/send', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        console.info('sendInvitation', JSON.stringify(res))
                        totalDocPendingForCirculation.value = res.data.data.totalDCirculated

                        if (res.data.data.totalDCirculated !== 0) {
                            creditSpent.value = res.data.data.creditSpent
                            creditBal.value = res.data.data.planCredit + res.data.data.bonusCredit
                        }
                        
                        isSent.value = true

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Invitation is sent. "+ res.data.data.creditSpent + ' credit' + (res.data.data.creditSpent === 1 ? ' is' : 's are') + ' deducted from your account.'
                        })

                    } else {
                        func.addLog('review', 'sendInvitation', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'insufficient_credit') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Insufficient credit.'
                                })

                            } else if (res.data.message === 'document_not_found' && res.data.description === 'no document status is draft') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'No draft document for sign request.'
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                            }
                        }
                    }

                    isLoading.value = false
            })
            .catch((error) => {
                isLoading.value = false
                func.addLog('review', 'sendInvitation - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                
            })
        }

        const delDoc = async () => {
        
            axios.delete( '/signon/' + objLSProj.value.id + '/' + docDetail.value.documentId)
                .then((res) => {
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        project.value = null
                        
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Document: " + docDetail.value.name + " is shredded."
                        })

                        randKey.value = randKey.value + 1

                        setTimeout(() => getProject(objLSProj.value.id), 1000)

                    } else {
                        func.addLog('review', 'delDoc', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('review', 'delDoc - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getCaptchaResend = () => {
            axios.get('/reference/captcha/RACTIVATE')
                .then((res) => {
                   
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        captchaImgResend.value = res.data.data.captcha.image
                        refIdResend.value = res.data.data.refId

                    } else {
                        func.addLog('review', 'getCaptchaResend', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('review', 'getCaptchaResend - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const resendActivation = () => {
            const p = {
                username: email.value,
                refId: refIdResend.value,
                captcha: captchaResend.value,
                frontendUrl: '/dashboard'
            }
          
            axios.post('/auth/signup/resend', p)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "An activation email has sent to you, please click the link to activate it."
                            })

                    } else {
                        func.addLog('review', 'resendActivation', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('review', 'resendActivation - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        onMounted(() => {

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }

            if (func.isEmptyObject(localStorage.getItem('project')) === false) {
                objLSProj.value = func.decodeStrToJsonSimple(localStorage.getItem('project'))
                // console.info('objLSProj', JSON.stringify(objLSProj.value))

            }

            if (func.isEmptyObject(localStorage.getItem('entity')) === false) {
                let objLSEntity = func.decodeStrToJsonSimple(localStorage.getItem('entity'))
                if ( objLSEntity.entityId === '1') {
                    isSignOn.value = true
                }
            }

            if (func.isEmptyObject(localStorage.getItem('planInfo')) === false) {
                objLSPlan.value = func.decodeStrToJsonSimple(localStorage.getItem('planInfo'))

            }
            

            getProject(objLSProj.value.id)
            if (getUserStatus.value !== 'PENDING') {
                getEmailTemplate()
            }
            getCaptchaResend()

            if (hasDocSignee === true) {
                alert.value.push({
                    class: "info",
                    title: "INFO",
                    message: "You may edit default message to signee above (optional)."
                })
            }

        })

        return { 
            store, alert, closeAlert, sessionId, project, totalDoc, subject, publicNote, selfNote,
            msg, msgHeader, msgFooter, msgHeaderHtml, msgFooterHtml,
            getUserStatus, getUserId, getEmailTemplate, sendInvitation,
            projectStatus, isEditMode, 
            addNote, delDoc, func, email, resendActivation, filterMsg,
            getCaptchaResend, captchaImgResend, refIdResend, captchaResend, 
            randKey, getProject, objLSProj, objLSPlan, viewOnline, 
            isSent, creditSpent, creditBal, 
            getPackage, getUserIsVerified, showKyc, showUpgrade, payInYear, hasDocSignee, 
            singleDocNSignee, lnkSignNow, isFocusMsg, isPreview, isLoading, dateExpiry, updDateExpiry, isSignOn, totalDocPendingForCirculation,
            showSealBtn, btnSendLabel, getDoc, docDetail, docName, unmarkDoc, unshredDoc
         }
    }
}
</script>

<style>
@keyframes blink {
    0% {
        backgound-color: #CFF4FC;
    }

    50% {
        background-color: transparent;
    }

    100% {
        background-color: #CFF4FC;
    }
}

#userMsg {
    animation: blink 2s infinite;
}

.notifMsg {
    position: absolute; 
    top: 62%; 
    left: 1%; 
    width: 98%; 
    z-index: 999;
}

.divButton {
    padding-bottom: 20px;
}

@media (min-width: 576px) { 
    .notifMsg {
        position: absolute; 
        top: 53%; 
        left: 1%; 
        width: 98%; 
        z-index: 999;
    }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    .previewOuterBox {
        height: 520px;
        padding-bottom: 10px;
    }

    .previewBox {
        transform: scale(0.80);
        transform-origin: top center;
    }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .previewOuterBox {
        height: 510px;
    }

    .previewBox {
        transform: scale(0.80);
        transform-origin: top center;
    }
}

@media (min-width: 1300px) { 
    .previewOuterBox {
        height: 485px;
    }

    .previewBox {
        transform: scale(0.80);
        transform-origin: top center;
    }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 
    .previewOuterBox {
        height: 510px;
    }

    .previewBox {
        transform: scale(0.85);
        transform-origin: top center;
    }
}

@media (min-width: 1600px) { 
    .previewOuterBox {
        height: 605px;
    }

    .previewBox {
        transform: scale(1);
        transform-origin: top center;
    }
}

.previewBox a {
    pointer-events: none;
    cursor: default;
}

body {
    color: #6E777E;
    font-size: 14px;
    overflow: scroll;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--form-control-text);
  background-color: var(--form-control-grey);
  background-clip: padding-box;
  border: 1px solid var(--accent-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  color: var(--form-control-text);
  background-color: var(--bgcolor-body-color);
  border-color: var(--bgcolor-body-color);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--accent-color);
}

textarea {
  padding: 5px 10px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  color: var(--form-control-text);
  background-color: var(--form-control-grey);
  font-size: 16px;
  resize: none;
}

textarea:focus {
    border-color: solid var(--accent-color);
    box-shadow: 0 0 0 0.25rem var(--accent-color);
    outline: 0;
}

.headerSuccess {
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px; 
    border-bottom-right-radius: 0px;
}

.bodySuccess {
    padding: 20px;
}
</style>